// user account
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import {QRCodeSVG} from 'qrcode.react';
import {QrScanner} from '@yudiel/react-qr-scanner';

import WebbDividerMedium from "../webx/webb-divider-md";
import WebbDividerSmall from "../webx/webb-divider-sm";
import FormNeeded from '../webx/form-needed'

import { GetUserForm } from '../../services/srvc-utilities'
import { GetLocalUser, GetLocalBusiness } from '../../services/srvc-auth-local'

import listActions from '../../data.static/data-asset-actions.json'

import { CredentialUserStatus } from '../../services/srvc-credentials-realm'
import { CredentialStatusCheckSet } from "../../services/srvc-credentials-realm";


export default function UnitActionsScanModule (props) {

  const usxx = GetUserForm()
  const usrx = GetLocalUser()
  const temx = GetLocalBusiness()
  const asset = usxx === 'user' ? usrx : temx
  
  const navigate = useNavigate()
  const {id} = useParams()

  const [loader, setLoader] = useState(true)
  const [submit, setSubmit] = useState(false)
  const [done, setDone] = useState(false)

  const [form, setForm] = useState(false);
  const [code, setCode] = useState(false)
  const [check, setCheck] = useState(false)

  const [memo, setMemo] = useState('Enter Code and Submit');

  const [actions, setActions] = useState([]);
  const [status, setStatus] = useState(props?.data?.status || {})

  const [scanstatus, setScanStatus] = useState(false)
  const [scan, setScan] = useState('******')
  const [ticket, setTicket] = useState()
  const [ticketstatus, setTicketStatus] = useState(false)

  const [cred, setCred] = useState(props?.data || {});
  const [data, setData] = useState({
    number: '',
    code: '',
    trxn: ''
  })


  useEffect( () => {

    if (asset){
      const fetchData = async() => {
        setLoader(true);

        var actionx = listActions.data.filter(x => x.actv)
        actionx = Array.from(actionx, x => { return {...x, live: true}})
        
        setActions(Array.from(actionx, x => { return {...x, live: true}}))

        setLoader(false);
      }
      fetchData()
    } else {}
  },[status]);

  useEffect(() => {
    
    setForm(false)
    if (data.code !=="" && data.code.length == 6 && !isNaN(data.code)) 
    setForm(true)

  }, [data.code])

  useEffect( () => {
    const fetchData = async() => {
      setLoader(true);
      console.log (scan.split('.')[2])
      if (scanstatus && scan !== '******') {
        const result = await CredentialUserStatus({
          data: {user: scan.split('.')[2], event: id}, srvc: '******'
        })
        console.log (result)
        if (result.stat) {
          setTicketStatus(true)
          setTicket(result.data)
        } else {
          setTicketStatus(false)
          setTicket(null)
        }
      }
      
      if (!scanstatus) console.log('******')

      setLoader(false);
      }
    fetchData()
  },[scanstatus]);


  const handleClick = (item) => {
    if (item !=='')
      navigate(`/${asset.role}/units/${id}/${item}`)
    else {}
  }

  const handleChange = async(key, val) => {
    setData({ ...data, [key]: val });
  }

  const handleCheck = async() => {
    

  }



  if (loader) return <></>


  return (
  <>

    {/* data */}
    <div className="rounded-xd back-color-wite p-1">
      
      <div className='border-none'>
        <div className={scanstatus ? 'd-none' : 'p-2 rounded'} style={{backgroundColor:'#FFF'}}>
          <QrScanner
            onDecode={(result) => {setScanStatus(true); setScan(result); console.log(result)}}
            onError={(error) => console.log(error?.message)}
            tracker={!scan}
            style={{ width: '100%', height: '100%' }}
          />
        </div>

        <div className={scanstatus && loader ? 'text-center' : 'd-none'} style={{}}>
          Please Wait...
        </div>

        {/* ticket not found */}
        <div className={scanstatus && !loader && !ticketstatus ? '' : 'd-none'} style={{}}>
          <div className="text-center p-3">
            <p className="text-normal text-bold">Event Ticket Not Found</p>

            <WebbDividerSmall />
            <WebbDividerSmall />
            <div className="" onClick={() => window.location.reload()}>
              <button className="btn btn-primary w-100 rounded-xx text-small ">
                SCAN AGAIN
              </button>
            </div>
          </div>  

        </div>

        {/* ticket found */}
        <div className={scanstatus && !loader  && ticketstatus? '' : 'd-none'} style={{}}>
          <div className="text-center">
            <img src={ticket?.media?.link || '******'} className="w-100 rounded-xd"></img>

            <p className="text-normal text-bold mt-2 mb-1 text-sm">{ticket?.unit?.name || '******'}</p>
            <p className="text-small mb-1">{ticket?.number || '******'}</p>
            <p className="m-0 mx-3 text-md d-none">{ticket?.unit?.memo || '******'}</p>

            <WebbDividerSmall />
            <div className="mx-3 border-bottom"></div>
            <WebbDividerSmall />
            <p className="text-lead text-bold mt-2 mb-1 text-sm">{ticket?.user?.name || '******'}</p>
            <p className="m-0 text-sm">{ticket?.user?.mail || '******'}</p>

            <WebbDividerSmall />
            <WebbDividerSmall />

            <div className={ticket?.status?.check ? '' : 'd-none'}>
                User is Checked In
            </div>


            <div className={ticket?.status?.check ? 'd-none' : 'mx-3'}>

              <button className="btn btn-primary w-100 rounded-xx text-small ">
                Check In User
              </button>

            </div>


            <WebbDividerSmall />
            <WebbDividerSmall />
          </div>

        </div>

      </div>
    </div>

    {/* action-mint */}

    {/* action-code */}

    {/* action-scan */}

    {/* blank modal */}


  </>

  )
}