// user account
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import {QRCodeSVG} from 'qrcode.react';
import {QrScanner} from '@yudiel/react-qr-scanner';

import WebbDividerMedium from "../webx/webb-divider-md";
import WebbDividerSmall from "../webx/webb-divider-sm";
import FormNeeded from '../webx/form-needed'

import { GetUserForm } from '../../services/srvc-utilities'
import { GetLocalUser, GetLocalBusiness } from '../../services/srvc-auth-local'

import listActions from '../../data.static/data-asset-actions.json'

import { CredentialUserStatus } from '../../services/srvc-credentials-realm'
import { CredentialStatusCheckSet } from "../../services/srvc-credentials-realm";


export default function UnitActionsModule (props) {

  const usxx = GetUserForm()
  const usrx = GetLocalUser()
  const temx = GetLocalBusiness()
  const asset = usxx === 'user' ? usrx : temx
  
  const navigate = useNavigate()
  const {id} = useParams()

  const [loader, setLoader] = useState(true)
  const [submit, setSubmit] = useState(false)
  const [done, setDone] = useState(false)

  const [form, setForm] = useState(false);
  const [code, setCode] = useState(false)
  const [check, setCheck] = useState(false)

  const [memo, setMemo] = useState('Enter Code and Submit');

  const [actions, setActions] = useState([]);
  const [status, setStatus] = useState(props?.data?.status || {})

  const [scanstatus, setScanStatus] = useState(false)
  const [scan, setScan] = useState('******')
  const [user, setUser] = useState()
  const [ticketstatus, setTicketStatus] = useState(false)

  const [cred, setCred] = useState(props?.data || {});
  const [data, setData] = useState({
    number: '',
    code: '',
    trxn: ''
  })


  useEffect( () => {

    if (asset){
      const fetchData = async() => {
        setLoader(true);

        var actionx = listActions.data.filter(x => x.actv)
        actionx = Array.from(actionx, x => { return {...x, live: true}})
        
        setActions(Array.from(actionx, x => { return {...x, live: true}}))

        setLoader(false);
      }
      fetchData()
    } else {}
  },[status]);

  useEffect(() => {
    
    setForm(false)
    if (data.code !=="" && data.code.length == 6 && !isNaN(data.code)) 
    setForm(true)

  }, [data.code])

  useEffect( () => {
    const fetchData = async() => {
      setLoader(true);
      console.log (scan.split('.')[2])
      if (scanstatus && scan !== '******') {
        const result = await CredentialUserStatus({
          data: {user: scan.split('.')[2], event: id}, srvc: '******'
        })
        console.log (result)
        if (result.stat) {
          setTicketStatus(true)

        } else {
          setTicketStatus(false)
        }
      }
      
      if (!scanstatus) console.log('******')

      setLoader(false);
      }
    fetchData()
  },[scanstatus]);


  const handleClick = (item) => {
    if (item !=='')
      navigate(`/${asset.role}/units/${id}/${item}`)
    else {}
  }

  const handleChange = async(key, val) => {
    setData({ ...data, [key]: val });
  }

  const handleCheck = async() => {
    

  }



  if (loader) return <></>


  return (
  <>

    {/* data */}
    <div className="rounded-xd back-color-wite p-2">
      <div className="mb-1"></div>
      <div className={`row row-cols-${actions.length} g-1`}>
      {actions && actions.map((item, i) => (
        <div className="col text-center" key={i}>
        
          <div className="d-flex justify-content-center">
            <div className="" data-bs-toggle='modal' 
              data-bs-target={item.live && item.mode == 'modal' ? `#${item.code}` : `#x`}
              onClick={() => handleClick(item.mode == 'link' ? item.link : '')}
              >
              {/* data-bs-target={`#${item.name}`}  */}
              <i className={`${item.icon} text-icon-sm  back-color-lite p-2 rounded-xx  ${item.live ? 'cursor hidark' : 'text-color-tone'}`} 
                style={{width: '3rem', height: '3rem'}}>  
              </i>
            </div>

          </div>
          <div className="mt-1">
            <p className={`text-mini text-uppercase text-truncate m-0 ${item.live ? '' : 'text-color-tone'}`}>{item.name}</p>
          </div>

        </div>
      ))}
      </div>
    </div>

    {/* action-mint */}
    

    {/* action-code */}
    <div className="modal fade" id="code" tabIndex="-1">
      <div className="modal-dialog">
        <div className='modal-content p-0 m-0 rounded-xd'>

          <div className='modal-header border-none d-none'>
            <p className='text-normal align-middle m-0 p-0'>QuickShare</p>
            <button type='button' className='btn-close text-small' data-bs-dismiss='modal' aria-label='Close'></button>
          </div>

          <div className='modal-body border-none'>
            <div className="text-center">
              <p className='text-normal text-bold m-0 text-truncate'>{props?.data?.meta?.name || '******'}</p>
              <p className='text-lead m-0'>{props?.data?.webx?.number || '******'}</p>
            </div>

            <WebbDividerSmall/>
            <div className="">  
              <div className="text-center border p-3 rounded-xd">
                <QRCodeSVG value={`https://minx.bharat.id/sx/${id || ''}`} size={''} includeMargin={false} />
              </div>
              
            </div>
          </div>

          <div className='d-flex justify-content-center modal-footer border-none'>
            <div className="">
              <button className='btn btn-outline-secondary btn-sm text-small rounded-xx'
                data-bs-dismiss='modal'
              >Close</button>
            </div>
          </div>
        </div>
      </div>
    </div> 

    {/* action-scan */}
    
        

    {/* blank modal */}
    <div className="modal fade d-none" id="x" tabIndex="-1">
      <div className="modal-dialog d-none"></div>
    </div>

  </>

  )
}